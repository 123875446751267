<template>
  <div>
    <div class="uk-padding-small">
      <vk-breadcrumb class="uk-margin-remove">
        <vk-breadcrumb-item style="cursor: pointer" @click="$router.push({name: 'users'})">Liste des utilisateurs</vk-breadcrumb-item>
        <vk-breadcrumb-item :disabled="true">Utilisateur {{ $route.params.userId }}</vk-breadcrumb-item>
      </vk-breadcrumb>
    </div>
    <div class="uk-padding-small">
      <h5>Utilisateur</h5>
      <User
          :user="getUserFromList($route.params.userId)"
          :show-roles-selection="true"
          :show-password-fields="true"
          :user-record-method="recordUser"
      />
    </div>
  </div>
</template>

<script>
import backendApi from '@/backend/api'
import User from "@/components/User";
import {mapActions, mapGetters, mapMutations} from "vuex";
import store from "@/store";

export default {
  name: "userEdit",
  components: {User},
  data: () => ({}),
  computed: {
    ...mapGetters('users', ['getUserFromList']),
  },
  methods: {
    ...mapMutations('users', ['UPDATE_USERS']),
    ...mapActions('notifications', ['addNotification']),
    recordUser(userData) {
      const siteUrl = {"siteUrl": window.location.protocol + '//' + window.location.host}
      userData = {...userData, ...siteUrl}
      return backendApi
          .updateUser(this.$store.state.login.user.token, userData)
          .then(data => {
            this.UPDATE_USERS(data)
            this.addNotification({status: 'success', message: 'Utilisateur modifié avec succès'})
          })
          .catch(backendApi.notificationOnError)
    },
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch('users/updateUsersList')
    next()
  },
  beforeRouteUpdate(to, from, next) {
    store.dispatch('users/updateUsersList')
    next()
  }
}
</script>

<style scoped>

</style>
